import React from "react"
import Layout from "../components/Layout"
import "bulma/css/bulma.min.css"
import "./index.css"
import "@fontsource/montserrat"

export default function NotFound() {
  return (
    <Layout>
      <div className="container is-fluid">
        <section
          style={{
            minHeight: "calc(100vh - 80px - 168px)",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            className="notification"
            style={{ backgroundColor: "#749ed3", color: "white" }}
          >
            Oops, la page que vous cherchez n'existe pas ou a été déplacé
          </p>
        </section>
      </div>
    </Layout>
  )
}
