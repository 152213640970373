import React, { useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import logo from "../images/logo-lo.png"

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <nav
      className="navbar is-fixed-top has-shadow"
      style={{ height: 80 }}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand" style={{ height: 80 }}>
        <AnchorLink className="navbar-item" to="/">
          <img
            style={{ maxHeight: "4rem", width: 167, height: 64 }}
            src={logo}
            alt="logo l'ô du jardin"
          />
        </AnchorLink>
        <button
          className={`navbar-burger ${isOpen ? "is-active" : ""}`}
          style={{ height: "100%" }}
          aria-label="menu"
          aria-expanded="false"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div className={`navbar-menu ${isOpen ? "is-active" : ""}`}>
        <div className="navbar-end" style={{ textAlign: "right" }}>
          <AnchorLink
            className="navbar-item"
            to="/#header"
            // onAnchorLinkClick={() => setIsOpen(!isOpen)}
          >
            Accueil
          </AnchorLink>
          <AnchorLink
            className="navbar-item"
            to="/#services"
            // onAnchorLinkClick={() => setIsOpen(!isOpen)}
          >
            Services
          </AnchorLink>
          <AnchorLink
            className="navbar-item"
            to="/#contact"
            // onAnchorLinkClick={() => setIsOpen(!isOpen)}
          >
            Contact
          </AnchorLink>
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          transform: "translate(-50%, -50%)",
          top: 80,
          left: "50%",
          backgroundColor: "white",
          textAlign: "center",
          borderRadius: 10,
          minWidth: 148,
        }}
        className="navbar-item"
      >
        <a href="tel:+33667536930">
          <strong className="phone-number">06 67 53 69 30</strong>
        </a>
      </div>
    </nav>
  )
}
